import React, {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {createPortal} from "react-dom";
import {IMaskInput} from "react-imask";

import {DataContext} from '~components/DataContext';
import Logout from '~components/Logout/Logout';
import SearchInput from '~components/Search/Search';
import Loader from '~components/Loader/Loader';
import Button from "~components/Button/Button";

import getApiData from '~utils/getApiData';
import useCheckUserGroup from '~utils/useCheckUserGroup';
import axios from "~utils/axiosSetup";
import useConfirm from '~utils/useConfirm';
import {useDebounce} from "~utils/useDebounce.js";

import icon from '~img/sidebar/icon.svg';
import uploadIcon from '~img/icons/upload.svg';
import settingIcon from '~img/icons/setting.svg';
import {ReactComponent as CloseIcon} from "~img/icons/close.svg";
import successIcon from "~img/icons/check.svg";

function Clients() {
    const navigate = useNavigate();
    const {user} = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [storeProfile, setStoreProfile] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [storeEmail, setStoreEmail] = useState('');
    const formData = {}
    const [emailStatus, setEmailStatus] = useState(null);
    const [formSend, setFormSend] = useState(false);
    const [deleteData, setDeleteData] = useState(false);
    const [deleteDataError, setDeleteDataError] = useState(false);
    const [formSubject, setFormSubject] = useState('Новые данные для рекламно-аналитической платформы');
    const isAdmin = useCheckUserGroup('Администратор');
    const [dataProcessing, setDataProcessing] = useState(false);
    const [deleteProcessing, setDeleteProcessing] = useState(false);
    const { confirm, confirmComponent } = useConfirm();

    useEffect(() => {
        if (userData) {
            setEmail(userData.email || '');
            setStoreEmail(userData.email || '');
        }
    }, [userData, setStoreEmail]);

    const fileChange = async e => {
        let file = (e.target.files[0])
        const formData = new FormData();

        formData.append('file', file);
        formData.append('profile', e.target.id);

        try {
            await axios.post(`${window.location.origin.replace('3000', '8000')}/api/change-logo/`, formData)
        } catch (error) {
            alert('Ошибка изменения данных.');
        } finally {
            getApiData(`profile/all`, setStoreProfile, setLoading, navigate)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    setLoading(true);
                    const pm_id = user.id;
                    const url = isAdmin ? `profile/all` : (pm_id ? `profile/all?pm_id=${pm_id}` : `profile/all`);
                    await getApiData(url, setStoreProfile, setLoading, navigate);
                } catch (error) {
                    console.error("Ошибка при загрузке данных:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchData();

    }, [user, navigate, setStoreProfile, isAdmin]);

    useEffect(() => {
        if (storeProfile) {
            const results = storeProfile
                .filter(client =>
                    client.client_name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            setSearchResults(results);
        }
    }, [searchTerm, storeProfile]);

    const getClientProfile = (currentProfile) => {
        sessionStorage.setItem('currentProfile', currentProfile);
        navigate('/')
    };

    storeProfile.sort(function (a, b) {
        return a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase());
    });

    const openModal = async (user_id) => {
        try {
            const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/auth_data/${user_id}`);

            if (response.status === 200) {

                if (response.data.email) {
                    setFormSend(true)
                }

                setUserData(response.data);
                setTimeout(() => {
                    setShowModal(true);
                }, 25)

            } else {
                console.error('Ошибка при загрузке информации профиля клиента');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onClose = () => {
        setShowModal(false);
        setShowModalSuccess(false);
        setEmailStatus(null)
        setError(null)
        setFormSend(false)
        setDeleteData(false)
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const findEmail = async (email_data, user_id) => {
        try {
            const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/find_email/${user_id}/${email_data}`);
            if (response.status === 200 && response.data.success === false) {
                setEmailStatus(response.data.status)
                setFormSend(false)
            } else {
                setEmailStatus(response.data.status)
                setFormSend(true)
                setError(null)
            }
        } catch (error) {
            console.error(error);
            setEmailStatus(error)
            setFormSend(false)
        }
    };

    const debouncedFindEmail = useDebounce(findEmail, 300);

    const handleChange = (e, user_id) => {
        const email = e.target.value;
        setEmail(email);

        if (email) {
            debouncedFindEmail(email, user_id);
        }
    };

    if (loading || !user) {
        return (
            <div className="content__wrapper content__wrapper_loader">
                <Loader/>
            </div>
        );
    }

    const handleDeleteData = async (e, user_id) => {

        setDeleteProcessing(true)

        const isConfirmed = await confirm("Вы уверены, что хотите удалить клиента?");
        if (isConfirmed) {
            try {
                const response = await axios.post(`${window.location.origin.replace('3000', '8000')}/api/auth_data_delete/${user_id}`);

                if (response.status === 200) {
                    setDeleteData(response.data.status)
                    setDeleteProcessing(false)

                    setEmail('')
                    setStoreEmail('')
                    setFormSend(false)
                    setTimeout(() => {
                        setDeleteData(false);
                    }, 5000)
                }

            } catch (error) {
                setDeleteProcessing(false)
                console.error(error.response.data.error ? error.response.data.error : error);
                setDeleteDataError(error.response.data.error ? error.response.data.error : 'Ошибка сохранения данных')
            }
        } else {
            setDeleteProcessing(false)
        }
    }

    const handleSubmit = async (e, user_id) => {
        e.preventDefault();
        setDataProcessing(true)

        if (storeEmail !== '') {
            setFormSubject('Обновление данных для рекламно-аналитической платформы')
        }

        const data = {
            site_url: window.location.hostname,
            email: email,
            subject: formSubject,
            updateData: storeEmail !== '',
            ...formData
        };

        try {
            const response = await axios.post(`${window.location.origin.replace('3000', '8000')}/api/auth_data_change/${user_id}`, data);
            setModalLoading(true)
            if (response.status === 200) {
                setError(response.data.error)
                setModalLoading(false)
                setShowModal(false);
                setDataProcessing(false)

                if (response.data.success === true) {
                    setShowModalSuccess(true);
                    setTimeout(() => {
                        setShowModalSuccess(false)
                    }, 3000)
                }

            } else {
                setDataProcessing(false)
                setError('Ошибка сохранения данных')
            }
        } catch (error) {
            setDataProcessing(false)
            console.error(error.response.data.error ? error.response.data.error : error);
            setError(error.response.data.error ? error.response.data.error : 'Ошибка сохранения данных')
        }
    };

    if (storeProfile) {
        return (
            <React.Fragment>
                <div className="home__wrapper">
                    <div className="sidebar__wrapper sidebar__wrapper_admin">
                        <Logout hide={true}/>
                        <div className="sidebar__icon">
                            <img alt="icon" src={icon}/>
                        </div>
                        <div className="sidebar__user">
                            Синергиум
                        </div>
                    </div>
                    <div className="content__wrapper">
                        <div className="clients__wrapper">
                            <SearchInput
                                searchTerm={searchTerm}
                                onSearchChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Найти"
                            />
                            <div className="clients__title bold">
                                Личные кабинеты клиник
                            </div>

                            <div className="clients__items">
                                {(storeProfile.length === 0 &&
                                    <div className="clients__item">Вам ещё не назначили ни одного клиента, обратитесь к
                                        своему тимлиду!</div>
                                )}
                                {searchTerm === '' ?
                                    <React.Fragment>
                                        {storeProfile.map((profile) => (
                                            <div key={profile.id} className="clients__item">
                                                <div className="clients__item-info"
                                                     onClick={() => getClientProfile(profile.client_name)}>
                                                    {profile.client_logo &&
                                                        <img className="clients__item-logo" alt={'clinic logo'}
                                                             src={`${window.location.origin.replace('3000', '8000')}${profile.client_logo}`}
                                                        />
                                                    }
                                                    {profile.client_name &&
                                                        <div className="clients__item-name">
                                                            {profile.client_name}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="clients__item-icon clients__item-setting">
                                                    <button type="button" onClick={() => openModal(profile.user)}
                                                            className="clients__item-upload-icon">
                                                        <img src={settingIcon} alt="Настройка"/>
                                                    </button>
                                                </div>
                                                <div className=" clients__item-icon clients__item-upload">
                                                    <label className="clients__item-upload-icon">
                                                        <img src={uploadIcon} alt="Загрузка"/>
                                                        <input type="file"
                                                               name="file"
                                                               id={profile.id}
                                                               className="upload__input"
                                                               onChange={fileChange}
                                                        />
                                                    </label>

                                                    <div className="clients__item-upload-text bold">
                                                        Загрузить логотип клиники
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </React.Fragment> :
                                    <React.Fragment>
                                        {searchResults.map((profile) => (
                                            <div key={profile.id} className="clients__item">
                                                <div className="clients__item-info"
                                                     onClick={() => getClientProfile(profile.client_name)}>
                                                    {profile.client_logo &&
                                                        <img className="clients__item-logo" alt={'clinic logo'}
                                                             src={`${window.location.origin.replace('3000', '8000')}${profile.client_logo}`}
                                                        />
                                                    }
                                                    {profile.client_name &&
                                                        <div className="clients__item-name">
                                                            {profile.client_name}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="clients__item-icon clients__item-setting">
                                                    <button type="button" onClick={() => openModal(profile.user)}
                                                            className="clients__item-upload-icon">
                                                        <img src={settingIcon} alt="Загрузка"/>
                                                    </button>
                                                </div>

                                                <div className="clients__item-upload clients__item-icon">
                                                    <label className="clients__item-upload-icon">
                                                        <img src={uploadIcon} alt="Загрузка"/>
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            id={profile.id}
                                                            className="upload__input"
                                                            onChange={fileChange}
                                                        />
                                                    </label>
                                                    <div className="clients__item-upload-text bold">
                                                        Загрузить логотип клиники
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && createPortal(
                    <div className={`modal modal-mail`}>
                        <div className="modal__overlay" onClick={handleOverlayClick}>
                            <div className="modal__content">
                                {modalLoading ? (
                                    <Loader/>
                                ) : (
                                    <>
                                        <button className="modal__close" onClick={onClose}>
                                            <CloseIcon/>
                                        </button>
                                        <div className="modal__title">Редактировать данные для входа</div>
                                        <form className="form" onSubmit={(e) => handleSubmit(e, userData.id)}>
                                            <div className="form__group form__group_name">

                                                {(deleteData || deleteDataError) && (
                                                    <div className={`status__block ${deleteData && 'status__block_success'}`}>
                                                        {deleteData ? (
                                                            <span>{deleteData}</span>
                                                        ) : deleteDataError && (
                                                            <span>{deleteDataError}</span>
                                                        )}
                                                    </div>
                                                )}
                                                
                                                <label className="label">Почта клиента:</label>
                                                <div className='input-wrapper'>
                                                    <IMaskInput
                                                            className="input bold"
                                                            mask={/^\S*@?\S*$/}
                                                            name="email"
                                                            value={email || ''}
                                                            onChange={(e) => handleChange(e, userData.id)}
                                                    />

                                                    <span className={`status-label ${error || (emailStatus && !formSend) ? 'error' : emailStatus && formSend ? 'success' : ''}`}>
                                                        {error ? (
                                                            <span>{error}</span>
                                                        ) : (
                                                            emailStatus && <span>{emailStatus}</span>
                                                        )}
                                                    </span>

                                                    <input type="hidden"
                                                           name="subject"
                                                           value={formSubject}/>
                                                </div>
                                            </div>
                                            <div className="modal__text">
                                                Пароль генерируется автоматически при отправке данных клиенту.
                                            </div>

                                            <div className="modal__button-wrapper">
                                                <Button
                                                        variant="main"
                                                        className="button_save"
                                                        type="submit"
                                                        disabled={!formSend}
                                                        loading={dataProcessing}
                                                >
                                                    {storeEmail !== '' ? "Обновить данные клиента" : "Отправить доступы"}
                                                </Button>

                                                {storeEmail &&
                                                    <Button
                                                        variant="main"
                                                        className="button_delete-data"
                                                        type="button"
                                                        onClick={(e) => handleDeleteData(e, userData.id)}
                                                        loading={deleteProcessing}
                                                    >
                                                        Удалить доступ
                                                    </Button>
                                                }
                                            </div>
                                        </form>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>,
                    document.getElementById('modal-root')
                )}
                {confirmComponent}
                {showModalSuccess && createPortal(
                    <div className='modal modal-success'>
                        <div className="modal__overlay" onClick={handleOverlayClick}>
                            <div className="modal__content">
                                <button className="modal__close" onClick={onClose}>
                                    <CloseIcon/>
                                </button>
                                <div className="success-icon-wrapper">
                                    <img className="success-icon" src={successIcon} alt="Успешно"/>
                                </div>
                            </div>
                        </div>
                    </div>,
                    document.getElementById('modal-root')
                )}
            </React.Fragment>
        );
    }
}

export default Clients;
