import React, {useContext, useState, useEffect, useCallback} from 'react';
import {createPortal} from "react-dom";

import {DataContext} from "~components/DataContext";
import Contacts from "~components/Contacts/Contacts";
import Graph from "~components/Graph/Graph";
import Button from '~components/Button/Button';
import Calculate from "~components/Calculate/Calculate";
import Loader from "~components/Loader/Loader";

import {nowDate, firstMonth, nowYear, nowMonth, formatNumber} from "~utils/date";
import axios from "~utils/axiosSetup";
import getApiData from "~utils/getApiData";

import editIcon from "~img/icons/edit.png";
import icon from '~img/revenue/icon.svg';

import {IMaskInput} from "react-imask";
import {ReactComponent as CloseIcon} from "~img/icons/close.svg";

const Revenue = ({isEdit}) => {
    const {data, profile} = useContext(DataContext);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [budget, setBudget] = useState([]);
    const [finance, setFinance] = useState(false);
    const [changeYear, setChangeYear] = useState(data?.year || nowYear());
    const [changeMonth, setChangeMonth] = useState(data?.month || firstMonth());
    const [changeBudget, setChangeBudget] = useState('');
    const [currentMonthBudget, setCurrentMonthBudget] = useState(null);

    useEffect(() => {
        if (profile.id) {
            getApiData(`budget/profile/${profile.id}`, (fetchedData) => {
                setFinance(fetchedData[1]);
                setLoading(false);
            }, setLoading);
        } else {
            setLoading(false);
        }
    }, [profile.id]);

    useEffect(() => {
        const fetchBudget = async () => {
            try {
                const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/change-revenue/${profile.id}`);
                if (response.status === 200) {
                    setBudget(response.data);
                } else {
                    console.error('Ошибка при загрузке данных клиентов');
                }
            } catch (error) {
                console.error("Ошибка при загрузке данных клиентов:", error);
            }
        };

        fetchBudget();
    }, [profile.id]);

    useEffect(() => {
        if (budget.length > 0) {
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth() + 1;

            const filteredCurrentMonthBudget = budget.find(
                (item) => item.year === currentYear && item.month === currentMonth
            );

            setCurrentMonthBudget(filteredCurrentMonthBudget ? filteredCurrentMonthBudget.budget_amount : null);
        }
    }, [budget]);

    useEffect(() => {
        const filteredBudget = budget.find(
            (item) => item.year === parseInt(changeYear) && item.month === parseInt(changeMonth)
        );

        setChangeBudget(filteredBudget ? filteredBudget.budget_amount : '');
    }, [changeMonth, changeYear, budget]);

    const openModal = useCallback(() => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1;
        setChangeYear(currentYear);
        setChangeMonth(currentMonth);

        const filteredBudget = budget.find(
            (item) => item.year === currentYear && item.month === currentMonth
        );

        setChangeBudget(filteredBudget ? filteredBudget.budget_amount : '');

        setCurrentMonthBudget(filteredBudget ? filteredBudget.budget_amount : null);

        setShowModal(true);
    }, [budget]);

    const onClose = () => {
        setShowModal(false);
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const handleSave = useCallback(async (event) => {
        event.preventDefault();

        const dataToSave = {
            year: parseInt(changeYear),
            month: parseInt(changeMonth),
            budget_amount: parseFloat(changeBudget),
            user_profile_id: profile.id
        };

        try {
            const response = await axios.post(`${window.location.origin.replace('3000', '8000')}/api/planned-ad-budget/`, dataToSave);
            if (response.status === 200) {
                setBudget((prevBudget) => {
                    const newBudget = prevBudget.filter(
                        (item) => !(item.year === dataToSave.year && item.month === dataToSave.month)
                    );
                    newBudget.push(response.data);
                    return newBudget;
                });
                setShowModal(false);
            } else {
                console.error('Ошибка при сохранении данных');
            }
        } catch (error) {
            console.error("Ошибка при сохранении данных:", error);
        }
    }, [changeYear, changeMonth, changeBudget, profile.id]);

    return (
        <>
            {loading ? (
                <div className="content__wrapper content__wrapper_loader">
                    <Loader/>
                </div>
            ) : (
                <div className="revenue__wrapper">
                    <div className="revenue__title-wrapper">
                        <div className="revenue__title bold">
                            Выручка / Рекламный бюджет (РБ)
                        </div>

                        <div className="revenue__plan">
                            Планируемый РБ
                            {Number(currentMonthBudget) !== 0 && currentMonthBudget !== null ? (
                                <>
                                    на {nowMonth()}:
                                    <span className="span">{formatNumber(Math.floor(currentMonthBudget))} р.</span>
                                </>
                            ) : (
                                <>
                                    :<span className="span bold bold_grey">0 р.</span>
                                </>
                            )}
                        </div>
                        <div className="revenue__balance">
                            Остаток на балансе
                            {budget && budget.budget ? (
                                <>
                                    на {nowDate()}:
                                    <span className="span">{formatNumber(budget.budget)} р.</span>
                                </>
                            ) : (
                                <>
                                    :<span className="span bold bold_grey">0 р.</span>
                                </>
                            )}
                        </div>
                    </div>

                    <Calculate/>

                    <div className="revenue__graph-wrapper">
                        <Graph budget={budget || []} finance={finance}/>
                    </div>

                    {budget?.pbi_link &&
                            <div className="revenue__analytics-wrapper">
                                <div className="revenue__analytics-title bold">
                                    Все цифры подробнее<br/>
                                    в сквозной аналитике
                                </div>
                                <Button href={budget.pbi_link} variant="white" className='revenue__analytics-button'
                                        icon={icon}>
                                Перейти в аналитику Статум
                            </Button>
                        </div>
                    }

                    <div className="revenue__contacts-wrapper">
                        <Contacts page={'revenue'}/>
                    </div>
                </div>
            )}

            {showModal && createPortal(
                <div className='modal modal-revenue'>
                    <div className="modal__overlay" onClick={handleOverlayClick}>
                        <div className="modal__content">
                            <button className="modal__close" onClick={onClose}>
                                <CloseIcon/>
                            </button>
                            <div className="modal__title bold">Редактировать РБ</div>
                            <form className="form revenue-form" onSubmit={handleSave}>
                                <div className="form__group form__group_name">
                                    <div>
                                        <label className="label">Год</label>
                                        <select className='select-year select'
                                                value={changeYear}
                                                onChange={(e) => setChangeYear(e.target.value)}>
                                            <option value={nowYear()}>{nowYear()}</option>
                                            <option value={nowYear(1)}>{nowYear(1)}</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="label">Месяц</label>
                                        <select className='select-month select'
                                                value={changeMonth}
                                                onChange={(e) => setChangeMonth(e.target.value)}>
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {new Date(0, i).toLocaleString('ru', {month: 'long'})}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="label">Рекламный бюджет</label>
                                        <div className='input-wrapper'>
                                            <IMaskInput
                                                className="input bold"
                                                mask={Number}
                                                thousandsSeparator=" "
                                                scale={0}
                                                value={Number(changeBudget) !== 0 && changeBudget !== null && changeBudget !== undefined ? changeBudget.toString() : ''}
                                                onAccept={(value) => setChangeBudget(value.replace(/\s+/g, ''))}
                                            />
                                            <div className='input-postfix'>
                                                р.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button variant="main" className="button_save" type='submit'>Сохранить</Button>
                            </form>
                        </div>
                    </div>
                </div>,
                document.getElementById('modal-root')
            )}
            {isEdit && (
                <Button variant="main"
                        className='edit-revenue__button button_edit'
                        icon={editIcon}
                        onClick={openModal}>
                    Редактировать
                </Button>
            )}
        </>
    );
};

export default Revenue;
