import { useState } from 'react';
import ConfirmModal from '~components/ConfirmModal/ConfirmModal';

const useConfirm = () => {
    const [confirmState, setConfirmState] = useState({
        isOpen: false,
        message: '',
        onConfirm: null,
        onClose: null,
    });

    const confirm = (message) => {
        return new Promise((resolve) => {
            const handleConfirm = () => {
                setConfirmState({ ...confirmState, isOpen: false });
                resolve(true);
            };

            const handleClose = () => {
                setConfirmState({ ...confirmState, isOpen: false });
                resolve(false);
            };

            setConfirmState({
                isOpen: true,
                message,
                onConfirm: handleConfirm,
                onClose: handleClose,
            });
        });
    };

    const confirmComponent = (
        <ConfirmModal
            isOpen={confirmState.isOpen}
            message={confirmState.message}
            onConfirm={confirmState.onConfirm}
            onClose={confirmState.onClose}
        />
    );

    return { confirm, confirmComponent };
};

export default useConfirm;
