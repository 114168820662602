import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import CheckUserGroup from '~utils/useCheckUserGroup';

import Login from '~components/Authorization/Login';
import LoginSSO from '~components/Authorization/LoginSSO';
import PrivateRoute from '~components/Authorization/PrivateRoute';

import Home from '~pages/Home/Home';
import Dashboard from '~pages/Dashboard/Dashboard';
import Tariff from '~pages/Tariff/Tariff';
import Revenue from '~pages/Revenue/Revenue';
import Sites from '~pages/Sites/Sites';
import Reports from '~pages/Reports/Reports';
import EditReports from '~pages/EditReports/EditReports';
import Clients from "~pages/Clients/Clients";
import ErrorPage from "~pages/ErrorPage/ErrorPage";

import './App.scss';

function App() {
    const isAdmin = CheckUserGroup('Администратор');
    const isManager = CheckUserGroup('Менеджер');
    const isEdit = !!(isAdmin || isManager)

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/login-sso" element={<LoginSSO/>}/>
                { isEdit && (
                    <Route path="clients" element={<Clients/>}/>
                )}
                <Route path="/" element={<PrivateRoute><Home/></PrivateRoute>}>
                    <Route index element={<Dashboard/>}/>
                    <Route path="tariff" element={<Tariff isEdit={isEdit}/>}/>
                    <Route path="revenue" element={<Revenue isEdit={isEdit}/>}/>
                    <Route path="sites" element={<Sites/>}/>
                    <Route path="reports" element={<Reports/>}/>
                    <Route path="reports-edit" element={<EditReports/>}/>
                    <Route path={'500'} element={<ErrorPage page='500'/>}/>
                </Route>
                <Route path={'*'} element={<ErrorPage page='404'/>}/>
            </Routes>
        </Router>
    );
}

export default App;
