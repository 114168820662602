import React from 'react';
import {Link} from 'react-router-dom';

import icon_500 from '~img/errorpage/500.png'
import icon_404 from '~img/errorpage/404.png'

const ErrorPage = (props) => {

    return (
        <div className='error-page__wrapper'>
            <div className="error-page__icon">
                {props.page === '404' ? (
                    <img alt={'Иконка'} src={icon_404}/>
                ) : (
                    <img alt={'Иконка'} src={icon_500}/>
                )}
            </div>

            <div className="error-page__title bold">
                {props.page}
            </div>

            {props.page === '404' ? (
                <div className="error-page__subtitle bold">
                    Такой страницы не существует
                </div>
            ) : (
                <div className="error-page__subtitle bold">
                    Идут технические работы
                </div>
            )}

            {props.page === '404' ? (
                <div className="error-page__text ">
                    Проверьте адрес ссылки или перейдите на&nbsp;
                    <Link to="/" className='link'>
                        главную страницу
                    </Link>
                </div>
            ) : (
                <div className="error-page__text ">
                    Пожалуйста, попробуйте загрузить страницу позже
                </div>
            )}
        </div>
    )
};

export default ErrorPage;
